import React, { useState } from "react";
import { Link } from "gatsby";
import "./ContentBlock.scss";
import { Waypoint } from "react-waypoint";

export default function (props) {
  const [active, setActive] = useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`content-block block-${props.visual.class} ${
          props.alternate ? "alternate" : "standard"
        } waypoint ${active ? "waypoint-active" : ""}`}
      >
        <div className="container container-large">
          <div className="content-block__inner">
            <div className="content-block__media">{props.visual.image}</div>
            <div className="content-block__content">
              <div className="content-block__content-group seperator seperator--large">
                <img
                  className="content-block__shape"
                  src={props.visual.shape}
                  alt="Description"
                />
                <h2 className="heading heading--l text--black">
                  {props.content.content_title.text}
                </h2>
                <div
                  className="text text--l"
                  dangerouslySetInnerHTML={{
                    __html: props.content.content_summary.html,
                  }}
                ></div>
                {props.content.link_text &&
                  props.content.link &&
                  props.content.link.uid && (
                    <Link
                      className="link-arrow"
                      to={`/${props.content.link.uid}/`}
                    >
                      {props.content.link_text}
                    </Link>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Waypoint>
  );
}
