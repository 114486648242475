import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Float from "./../lib/Float";
import "./AbstractGrid.scss";
import { Waypoint } from "react-waypoint";

const gridItems = [];
const CaseStudyLink = ({ project, index }) => {
  const item = project.case_studies.document.data;
  const uid = project.case_studies.document.uid;

  const [active, setActive] = useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <Link
        to={`/case-study/${uid}/`}
        key={`ag-${index}`}
        className={`abstract-grid__item item-${index + 1} waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <div
          ref={(div) => (gridItems[index] = div)}
          className="abstract-grid__item--inner"
        >
          <img
            className="abstract-grid__item-media"
            src={item.thumb.fluid.src}
            alt={item.title.text}
          />
          <div className="abstract-grid__item-overlay">
            <h6 className="heading heading--xxs text--extrabold uppercase">
              {item.client_name.text}
            </h6>
            <h4 className="heading heading--m text--black">
              {item.project_name}
            </h4>
          </div>
        </div>
      </Link>
    </Waypoint>
  );
};

export default function ({ content, items }) {
  useEffect(() => {
    if (window.innerWidth > 767) {
      gridItems.forEach((item, index) => {
        if (index === 1 || index === 4) {
          Float(item, index === 1 ? 1.5 : 3);
        } else {
          Float(item, 0.5);
        }
      });
    }
  }, []);

  const [active, setActive] = useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`abstract-grid waypoint ${active ? "waypoint-active" : ""}`}
      >
        <div className="">
          <div className="header-group seperator align-center">
            <h2 className="heading heading--s text--extrabold uppercase">
              {content.work_title.text}
            </h2>
          </div>
        </div>
        <div className="abstract-grid__items">
          {items
            .filter((item, index) => index < 6)
            .map((project, index) => {
              return <CaseStudyLink project={project} index={index} />;
            })}
        </div>
      </div>
    </Waypoint>
  );
}
