import React, { useState } from "react";
import "./ContentCTA.scss";
import { Link } from "gatsby";
import { Waypoint } from "react-waypoint";

import HeroIllustration from "./../assets/svg/animations/hero-illustration-2.inline.svg";

export default function ({ content, setVideo }) {
  const [active, setActive] = useState(false);

  // console.log(content);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };
  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`content-cta waypoint ${active ? "waypoint-active" : ""} ${
          content.video_thumbnail ? "has-thumbnail" : ""
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="content-cta__content">
              <div className="header-group seperator">
                <h1 className="heading heading--xs uppercase text--light">
                  {content.featured_subtitle.text}
                </h1>
                <h2 className="heading heading--l text--medium">
                  {content.featured_title.text}
                </h2>
                <div
                  className="text--l"
                  dangerouslySetInnerHTML={{
                    __html: content.featured_content.html,
                  }}
                ></div>
                <Link
                  to={`/${content.featured_link.uid}/`}
                  className="primary-link link-arrow"
                >
                  {content.featured_link_text}
                </Link>
                {content.video && content.video.embed_url && (
                  <div className="content-cta__video">
                    <button
                      onClick={() => {
                        setVideo(content.video.embed_url);
                      }}
                      className="button button-gray button-round"
                    >
                      {content.video_button.text}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {content.video_thumbnail ? (
              <div
                className="content-cta__thumbnail"
                onClick={() => {
                  setVideo(content.video.embed_url);
                }}
              >
                <img src={content.video_thumbnail.url}></img>
                <img
                  className="content-cta__thumbnail-icon"
                  src={require("./../assets/svg/icons/play.svg")}
                  alt="Play"
                />
              </div>
            ) : (
              <div className="content-cta__media">
                <HeroIllustration />
              </div>
            )}
          </div>
          <div className="row"></div>
        </div>
      </div>
    </Waypoint>
  );
}
