import React from "react";
import "./HomeHero.scss";
import { TweenMax } from "gsap";
import Float from "./../lib/Float";

const ITEMS = [
  "Video",
  "Animation",
  "Campaigns",
  "Social Media",
  "Commercials",
];

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.items = props.items.map((item) => {
      return {
        title: item.data.title,
        uid: item.uid,
      };
    });

    this.state = {
      open: false,
      mediaType: this.items[Math.floor(this.items.length * Math.random())],
    };
  }

  componentDidMount() {
    TweenMax.from(".home-hero", {
      duration: 0.3,
      delay: 0.25,
      opacity: 0,
      y: "-=10",
    });
    TweenMax.from(".hero-menu__current-item span", {
      duration: 0.25,
      stagger: 0.075,
      delay: 0.35,
      opacity: 0,
      y: "-=10",
    });
    TweenMax.from(this.button, {
      duration: 0.25,
      delay: 0.8,
      opacity: 0,
      y: "+=20",
    });
    Float(this.pink, 0.5);
    Float(this.green, 2);
  }

  render() {
    return (
      <div>
        <div className="home-hero__title verticle-text">
          <h1>Welcome</h1>
        </div>
        <div className="home-hero__wrapper">
          <div className="home-hero">
            <div className="home-hero__background">
              <svg
                className="shapes"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-50 -50 897.24 1267.13"
              >
                <g style={{ isolation: "isolate" }}>
                  <g>
                    <path
                      ref={(r) => (this.green = r)}
                      d="M423.75,327.86H419a415.1,415.1,0,0,0-148.79,27.43c-90.51,34.28-165.94,99.42-213.93,183.07h0A416.59,416.59,0,0,0,0,747.49c0,231.76,189.93,420.32,421.69,419.64h5.49a171.27,171.27,0,0,0,59.65-12.35h0c63.77-26,109-89.13,109-161.82V502.71C595.85,406.71,519.74,329.23,423.75,327.86Z"
                      style={{ fill: "#c51882", "mix-blend-mode": "multiply" }}
                    />
                    <path
                      ref={(r) => (this.pink = r)}
                      d="M625.14,0h-4.8A415.1,415.1,0,0,0,471.55,27.43C381,61.71,305.62,126.85,257.62,210.5h0a416.64,416.64,0,0,0-56.23,209.13c0,231.76,189.94,420.32,421.69,419.64h5.49a171.48,171.48,0,0,0,59.65-12.34h0c63.77-26.06,109-89.14,109-161.82V174.85A174.12,174.12,0,0,0,625.14,0Z"
                      style={{ fill: "#7fbebc", "mix-blend-mode": "multiply" }}
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className="home-hero__content">
              <div className="home-hero__content-inner">
                <div className="hero-menu">
                  <div className="hero-menu__inner">
                    <div className="hero-menu__description">
                      <span>
                        Create
                        <br />
                        Compelling
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        this.setState({ open: true });
                      }}
                      className="hero-menu__current-item"
                    >
                      {this.state.mediaType.title.split("").map((char) => {
                        if (char === " ") {
                          return <span>&nbsp;</span>;
                        }
                        return <span>{char}</span>;
                      })}
                    </div>
                  </div>
                  <div
                    className={`hero-menu__list ${
                      this.state.open ? "active" : ""
                    }`}
                  >
                    {this.items.map((item, index) => {
                      return (
                        <div
                          className={`hero-menu__list-item ${
                            this.state.mediaType.uid === item.uid
                              ? "current"
                              : ""
                          }`}
                          onClick={() => {
                            this.setState({ open: false, mediaType: item });
                          }}
                        >
                          <span>{item.title}</span>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    onClick={() => {
                      window.location.href = `/case-studies/${this.state.mediaType.uid}`;
                    }}
                    className="button button-primary button-round"
                    ref={(button) => (this.button = button)}
                  >
                    View Case Studies
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
