import React, { useState, useEffect } from "react";
import "./LogoGrid.scss";
import { Waypoint } from "react-waypoint";
export default function ({ content, items }) {
  const [active, setActive] = useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div className={`logo-grid waypoint ${active ? "waypoint-active" : ""}`}>
        <div className="header-group seperator align-center">
          <h2 className="heading heading--xs uppercase">
            {content.grid_title.text}
          </h2>
          <div
            className="text--s"
            dangerouslySetInnerHTML={{ __html: content.grid_intro.html }}
          ></div>
        </div>
        <div className="logo-grid__items">
          {items.map((client, index) => {
            if (client.link && client.link.uid && client.link.type) {
              const url = `/${
                client.link.type === "case_study" ? "case-study" : "article"
              }/${client.link.uid}`;
              return (
                <a
                  href={url}
                  key={`lg-${index}`}
                  className={`logo-grid__item item-${index}`}
                >
                  <img src={client.logo.fluid.src} alt={client.logo.alt} />
                </a>
              );
            }

            return (
              <div
                key={`lg-${index}`}
                className={`logo-grid__item item-${index}`}
              >
                <img src={client.logo.fluid.src} alt={client.logo.alt} />
              </div>
            );
          })}
        </div>
      </div>
    </Waypoint>
  );
}
