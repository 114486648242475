import React, { useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import Layout from "../components/layout";
import SEO from "../components/seo";

import HomeHero from "./../components/HomeHero";
import ContentCTA from "./../components/ContentCTA";
import AbstractGrid from "./../components/AbstractGrid";
import LogoGrid from "./../components/LogoGrid";
import LogoGridExternal from "./../components/LogoGridExternal";
import ClutchReviews from "./../components/ClutchReviews";
import ContentBlock from "./../components/ContentBlock";
import BlockQuote from "./../components/BlockQuote";
import VideoModal from "./../components/VideoModal";
import { CSSTransition } from "react-transition-group";

import HomeAnimation1 from "./../assets/svg/animations/home-illustration1.inline.svg";
import HomeAnimation2 from "./../assets/svg/animations/home-illustration2.inline.svg";
import HomeAnimation3 from "./../assets/svg/animations/home-illustration3.inline.svg";

export const query = graphql`
  {
    allPrismicCaseStudyCategory {
      nodes {
        uid
        data {
          title
        }
      }
    }
    allPrismicHomePage {
      nodes {
        data {
          title {
            text
          }
          meta_description
          meta_title
          canonical {
            uid
            type
          }
          body {
            ... on PrismicHomePageBodyFeaturedContent {
              id
              primary {
                featured_content {
                  html
                }
                featured_link {
                  uid
                }
                featured_link_text
                featured_subtitle {
                  text
                }
                featured_title {
                  text
                }
                video_button {
                  text
                }
                video {
                  embed_url
                  html
                  url
                }
                video_thumbnail {
                  alt
                  url
                }
              }
            }
            ... on PrismicHomePageBodyFeaturedWork {
              id
              primary {
                work_title {
                  html
                  text
                  raw
                }
              }
              items {
                case_studies {
                  url
                  document {
                    ... on PrismicCaseStudy {
                      id
                      uid
                      data {
                        thumb {
                          alt
                          fluid(maxWidth: 1800) {
                            src
                          }
                        }
                        title {
                          text
                        }
                        client_name {
                          text
                        }
                        project_name
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicHomePageBodyLogoGrid {
              id
              primary {
                grid_intro {
                  html
                }
                grid_title {
                  text
                }
              }
              items {
                link {
                  uid
                  type
                }
                logo {
                  alt
                  fluid {
                    src
                  }
                }
              }
            }
            ... on PrismicHomePageBodyQuoteSlider {
              id
              items {
                quote_company
                quote_person
                quote_text {
                  html
                  text
                }
              }
              primary {
                quote_title {
                  text
                }
              }
            }
            ... on PrismicHomePageBodyContentBlock {
              id
              primary {
                content_summary {
                  html
                }
                content_title {
                  text
                }
                icon_class
                link_text
                link {
                  uid
                }
              }
            }
            ... on PrismicHomePageBodyLogoGridWExternalLinks {
              id
              primary {
                grid_title {
                  text
                }
              }
              items {
                link {
                  url
                  target
                }
                logo {
                  alt
                  fluid {
                    src
                  }
                }
              }
            }
            ... on PrismicHomePageBodyRawHtml {
              id
            }
          }
        }
        uid
      }
    }
  }
`;

const Slices = ({ items, setVideo }) => {
  const BlocksDesign = [
    {
      class: "expertise",
      image: <HomeAnimation1 />,
      shape: require("./../assets/svg/shapes2.svg"),
    },
    {
      class: "process",
      image: <HomeAnimation2 />,
      shape: require("./../assets/svg/shapes3.svg"),
    },
    {
      class: "think",
      image: <HomeAnimation3 />,
      shape: require("./../assets/svg/shapes4.svg"),
    },
  ];

  let contentBlockCount = -1;

  return items.map((item) => {
    switch (item.__typename) {
      case "PrismicHomePageBodyFeaturedContent":
        return <ContentCTA content={item.primary} setVideo={setVideo} />;
      case "PrismicHomePageBodyFeaturedWork":
        return <AbstractGrid content={item.primary} items={item.items} />;
      case "PrismicHomePageBodyLogoGrid":
        return <LogoGrid content={item.primary} items={item.items} />;
      case "PrismicHomePageBodyQuoteSlider":
        return (
          <BlockQuote theme="dark" content={item.primary} items={item.items} />
        );
      case "PrismicHomePageBodyContentBlock":
        contentBlockCount += 1;
        if (contentBlockCount >= 3) {
          return null;
        }
        return (
          <ContentBlock
            key={`cb-${contentBlockCount}`}
            alternate={contentBlockCount % 2 !== 0}
            visual={BlocksDesign[contentBlockCount]}
            content={item.primary}
          />
        );
      case "PrismicHomePageBodyLogoGridWExternalLinks":
        return <LogoGridExternal content={item.primary} items={item.items} />;
      case "PrismicHomePageBodyRawHtml":
        return <ClutchReviews />;
      default:
        return null;
        break;
    }
  });
};

const IndexPage = (props) => {
  const { location } = props;
  const data = props.data.allPrismicHomePage.nodes[0].data;
  const meta = {
    descripition: data.meta_description,
    title: data.meta_title,
    uid: data.canonical.uid,
    pageType: data.canonical.type,
  };

  const [video, setVideo] = useState(null);

  return (
    <>
      <Layout location={location}>
        <SEO meta={meta} description={meta.descripition} title={meta.title} uid={meta.uid} pageType={meta.pageType} />
        <div className="home page">
          <HomeHero items={props.data.allPrismicCaseStudyCategory.nodes} />
          <Slices items={data.body} setVideo={setVideo} />
        </div>
      </Layout>
      <CSSTransition
        in={video !== null}
        timeout={{ enter: 300, exit: 300 }}
        unmountOnExit
        classNames="modal-animation"
      >
        <VideoModal close={() => setVideo(null)} videoURL={video} />
      </CSSTransition>
    </>
  );
};

IndexPage.query = query;

export default withPreview(IndexPage);
